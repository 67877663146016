.link-blog {
  color: $text-03;
  margin: 16px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    transition: .3s ease-out;
    text-decoration: none;
    color: $text-03;
    box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.2);

    .card-blog {
      .txt-blog {
        h5 {
          color: $primary-one;
        }
      }
    }
  }

  &:focus {
    box-shadow: 2px 4px 15px 0 rgba(1, 109, 56, .3);
    outline: none;
  }

  .card-blog {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    background-color: $white;

    .img-text {
      img {
        width: 100%;
      }
    }

    .txt-blog {
      padding: 16px;

      @media screen and (min-width: 990px) {
        min-height: 150px;
      }

      @media screen and (max-width: 640px) {
        padding: 8px;
      }

      h5 {
        color: $text-05;
        font-size: 18px;
        font-weight: bold;
      }

      p {
        @media screen and (max-width: 640px) {
          display: none;
        }
      }
    }
  }
}

#others-services {
  .row {
    .card-item {
      background: $white;
      padding: 0px;
      max-width: 360px;
      position: relative;
      margin: 10px;
      text-align: left;

      a {
        text-decoration: none;
      }

      p.title-card {
        font-size: 18px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
        color: $text-05;
        font-weight: 600;
        padding: 16px;
        position: absolute;
        bottom: -60px;
        width: 100%;
        transition: 0.2s;
        background: $white;
      }

      &:hover p {
        padding: 20px 16px;
        color: $primary-one;
      }

      &.text-only {
        top: 60px;

        a {
          font-size: 18px;
          color: $text-03;
          text-decoration: none;

          &:hover {
            color: $primary-one;
          }
        }
      }
    }

    @media screen and (max-width: 1180px) {
      .card-item {
        max-width: 290px;
      }
    }

    @media screen and (max-width: 768px) {
      .card-item {
        max-width: 46%;
      }
    }

    @media screen and (max-width: 510px) {
      .card-item {
        max-width: 80%;

      }
    }
  }

  @media screen and (max-width: 510px) {
    .item-services {
      h2 {
        justify-content: center;
      }

      .row {
        justify-content: center;
      }

      .card-item {
        max-width: 100%;
        min-width: 390px;
        margin: 20px 0 80px;
        ;

        img {
          width: 100%;
        }

        &.text-only {
          margin: 20px 0px;
          top: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    .item-services {
      .card-item {
        max-width: 100%;
        min-width: 90%;
        margin: 20px 0 80px;
        ;

        img {
          width: 100%;
        }
      }
    }
  }
}
