$icon-menu: "\e900";
$icon-arrow-down: "\e901";
$icon-agregar: "\e902";
$icon-alarma: "\e903";
$icon-auricular: "\e904";
$icon-bote: "\e905";
$icon-camion: "\e906";
$icon-carro: "\e907";
$icon-casa: "\e908";
$icon-casco: "\e909";
$icon-check: "\e90a";
$icon-corazon: "\e90b";
$icon-cruz: "\e90c";
$icon-documento: "\e90d";
$icon-edificio: "\e90e";
$icon-ejecutivo: "\e90f";
$icon-empresa: "\e910";
$icon-escudo-dinero: "\e911";
$icon-fuego: "\e912";
$icon-hotel: "\e913";
$icon-obrero: "\e914";
$icon-persona: "\e915";
$icon-personas: "\e916";
$icon-tarjeta: "\e917";
$icon-tractor: "\e918";
$icon-virrete: "\e919";
$icon-ctrl: "\ea50";
$icon-google: "\ea88";
$icon-google-plus: "\ea8b";
$icon-facebook: "\ea90";
$icon-instagram: "\ea92";
$icon-whatsapp: "\ea93";
$icon-youtube: "\ea9d";
