.header-transparent {
  transition: .3s ease-in-out;

  #header {
    height: 110px;
    background-color: transparent;
    box-shadow: none;

    @media screen and (max-width: 640px) {
      max-height: 60px;
    }

    .navbar-brand {
      transition: .3s ease-in-out;
      width: 150px;
      background-image: url("/assets/img/brand/seguros-blanco.svg");

      @media screen and (max-width: 640px) {
        width: 130px;
      }
    }
    .navbar-nav {
      .nav-link {
        color: $white;
        &:hover {
          color: $secondary-one;
        }
      }
      .nav-item.active {
        .nav-link {
          color: $secondary-one;
        }
      }
    }
  }
}

.header-white {
  transition: .3s ease-in-out;
  #header {
    .head-mobile {
      .icon-menu, .icon-persona {
        color: $text-03;
        font-size: 1.5rem;
      }
    }
    .text-link {
      color: $primary-one;
      font-size: 15px;
      font-weight: bold;
      line-height: 18px;
      text-transform: uppercase;
      &:hover {
          color: $primary-one-dark;
      }
  }
  .text-link.br {
    border-right: 1px solid $text-03;
  }
  .log {
    p {
      color: $text-03;
    }
    span.icon-persona {
      color: $text-03;
    }
  }
  }
  #navBrand {
    @media screen and (max-width: 640px) {
      width: 140px;
    }
  }
}

.dropdown-menu {
  transition: .3s ease-in-out;
  opacity: 0;
  border: 1px solid #e9ecef;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  top: 97%;
}

.dropdown-menu.show {
  opacity: 1;
}

.blog {
  .slick-dots {
    position: relative;
  }
}

.img-pefil {
  width: 100px;
  height: 100px;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  margin-bottom: 16px;
  img {
    width: 100%;
  }
}


//payments

.mat-form-field {
  width: 100%;
  &-appearance-outline {
    &.mat-focused {
      .mat-form-field-outline-thick, .mat-form-field-label {
        color: $primary-one;
      }
    }
    .mat-form-field-outline-thick {
      color: $primary-one;
      .mat-form-field-outline {
        &-start, &-gap, &-end {
          border-width: 1px !important  ;
        }
      }
    }
  }
}

.mat-checkbox {
  &-checked {
    &.mat-accent {
      .mat-checkbox-background {
        background-color: $primary-one;
      }
    }
  }
  &-layout {
    white-space: normal !important;
  }
}

.mat-button {
  &-toggle {
    width: 50%;
    &-checked {
      background-color: $ui-03;
      font-weight: bold;
    }
    &-group {
      &-appearance-standard {
        width: 100%;
      }
    }
  }
}

//dialog


.mat-dialog {
  &-container {
    padding: 0 !important;
  }
}


// radio button

.mat-radio-label {
  margin-right: 35px;
}

.mat-radio-button {
  &.mat-accent {
    .mat-radio-inner-circle {
      background-color: $primary-one;
    }
    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $primary-one;
      }
    }
  }
}

.brand-daviplata {
  height: 100px;
  img {
    width: 4cm;
    margin: 0 auto;
    display: block;
  }
}

app-purchase-credit-card.ng-star-inserted {
  width: 100%;
}

app-purchase-pse{
  width: 100%;
}
