#tabsQuoting, #protected {
    .tabs {
        .indicator {
            background-color: $primary-one;
        }
    }
    
    .carousel .carousel-item {
      width: 100%;
    }
}

#insurance {
    .tabs {
        .indicator {
            background-color: $secondary-one;
        }
    }
}