/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
//@import "~bootstrap/dist/css/bootstrap.css";
@import 'assets/styles/variables';
@import 'assets/styles/reset';
@import 'assets/styles/mixin';
@import 'assets/styles/icons';
@import 'assets/styles/fonts';
@import 'assets/styles/inputs';
@import 'assets/styles/tabs';
@import 'assets/styles/buttons';
@import 'assets/styles/cards';
@import 'assets/styles/colapse';
@import 'assets/styles/forms';
@import 'assets/styles/dialog';
@import 'assets/styles/tooltip';
@import 'assets/styles/paginator';
@import 'assets/styles/components';

:root {
  --padding-l: 13%;
  --padding-m: 4%;
}

body { overflow-y: scroll !important; }

.container-100{
    min-width: calc(100% - 2rem);
    max-width: calc(100% - 2rem);
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.mat-snack-bar-container.snackDanger {
    color: white;
    background: crimson;
}

.m-top{
    margin-top: 15px;
}

@media screen and (max-width:640px) {
  	.mat-snack-bar-container.withMin {
	    min-width: 200px;
	   	max-width: 100%;
	}
}
@media screen and (min-width:640px) {
  	.mat-snack-bar-container.withMin {
	    min-width: 600px;
	   	max-width: 100%;
	}
}
.btn-custom-primary {
    background-color: #016D38;
    color: #fff;
    font-size: 1rem;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: bold;
    padding: 0.75rem 1.9rem;
    text-transform: uppercase;
}
.btn-custom-primary:hover {
    color: #fff;
}

.btn-custom-suscription{
  background-color: #3085d6;
    color: #fff;
    font-size: 1rem;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: bold;
    padding: 0.75rem 1.9rem;
    margin-right: 0.75rem;
}

.btn-custom-suscription:hover{
    color: #fff;
}

.btn-custom-cancel{
  background-color: #aaa;
    color: #fff;
    font-size: 1rem;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: bold;
    padding: 0.75rem 1.9rem;
}

.btn-custom-cancel:hover{
    color: #fff;
}
