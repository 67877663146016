@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i');

@font-face {
  font-family: 'bolivar';
  src: url('/assets/fonts/bolivar.eot');
  src: url('/assets/fonts/bolivar.eot#iefix') format('embedded-opentype'),
    url('/assets/fonts/bolivar.ttf') format('truetype'),
    url('/assets/fonts/bolivar.woff') format('woff'),
    url('/assets/fonts/bolivar.svg#bolivar') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bolivar' !important;
  //speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-agregar {
  &:before {
    content: $icon-agregar;
  }
}
.icon-alarma {
  &:before {
    content: $icon-alarma;
  }
}
.icon-auricular {
  &:before {
    content: $icon-auricular;
  }
}
.icon-bote {
  &:before {
    content: $icon-bote;
  }
}
.icon-camion {
  &:before {
    content: $icon-camion;
  }
}
.icon-carro {
  &:before {
    content: $icon-carro;
  }
}
.icon-casa {
  &:before {
    content: $icon-casa;
  }
}
.icon-casco {
  &:before {
    content: $icon-casco;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-corazon {
  &:before {
    content: $icon-corazon;
  }
}
.icon-cruz {
  &:before {
    content: $icon-cruz;
  }
}
.icon-documento {
  &:before {
    content: $icon-documento;
  }
}
.icon-edificio {
  &:before {
    content: $icon-edificio;
  }
}
.icon-ejecutivo {
  &:before {
    content: $icon-ejecutivo;
  }
}
.icon-empresa {
  &:before {
    content: $icon-empresa;
  }
}
.icon-escudo-dinero {
  &:before {
    content: $icon-escudo-dinero;
  }
}
.icon-fuego {
  &:before {
    content: $icon-fuego;
  }
}
.icon-hotel {
  &:before {
    content: $icon-hotel;
  }
}
.icon-obrero {
  &:before {
    content: $icon-obrero;
  }
}
.icon-persona {
  &:before {
    content: $icon-persona;
  }
}
.icon-personas {
  &:before {
    content: $icon-personas;
  }
}
.icon-tarjeta {
  &:before {
    content: $icon-tarjeta;
  }
}
.icon-tractor {
  &:before {
    content: $icon-tractor;
  }
}
.icon-virrete {
  &:before {
    content: $icon-virrete;
  }
}
.icon-ctrl {
  &:before {
    content: $icon-ctrl;
  }
}
.icon-google {
  &:before {
    content: $icon-google;
  }
}
.icon-google-plus {
  &:before {
    content: $icon-google-plus;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
