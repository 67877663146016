.btn {
    border: 0;
}
.btn {
    box-shadow: none;
}
.btn-pill {
    border-radius: 20px;
}
.btn-primary {
    background-color: $primary-one;
    color: $white;
    font-size: 1rem;
    width: fit-content;
    font-weight: bold;
    border-radius: 50px;
    padding: .75rem 1.9rem;
    border-radius: 50px;
    text-transform: uppercase;
    &:hover {
        background-color: $primary-one-dark;
        color: $white;
    }
}
.btn-primary-outline {
  background-color: transparent;
  color: $primary-one;
  border: 1.5px solid $primary-one;
  font-size: 1rem;
  width: fit-content;
  font-weight: bold;
  border-radius: 50px;
  padding: .75rem 1.9rem;
  text-transform: uppercase;
  &:hover {
      background-color: $primary-one;
      color: $white;
  }
}
.btn-secondary {
    background-color: $secondary-one;
    color: $primary-one;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 50px;
    padding: .75rem 1.9rem;
    text-transform: uppercase;
    &:hover {
        background-color: $secondary-one-dark;
        color: $primary-one;
    }
}

.text-link-primary {
    color: $primary-one;
    font-size: 1rem;
    font-weight: bold;
    line-height: 18px;
    text-transform: uppercase;
    &:hover {
        color: $primary-one-dark;
    }
}

.text-link {
  color: $secondary-one;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  text-transform: uppercase;
  &:hover {
      color: $secondary-one-dark;
  }
}


