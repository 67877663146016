@import "../styles/variables";

* {
    font-family: $roboto-condensed;
    button {
        background-color: transparent;
        border: none;
        padding: 0;
        &:focus {
            outline: none !important;
        }

    }
}

body {
    font-family: $roboto-condensed;
    background-color:  '#fff';//$complementary-one;
    // background-repeat: no-repeat;
    // background-position: center calc(100vh - 140px);
    color: black;
    line-height: 1.5;
    list-style: none;
}

img {
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $roboto-condensed;
}

p {
    margin: 0;
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: $default-controls-02;
    &:hover {
        color: $default-controls-02-dark;
    }
}

nav {
    line-height: normal;
}
