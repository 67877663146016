
/* =============  FONTS =============  */

$roboto-condensed: 'Roboto Condensed', sans-serif;
$font-family-icons: 'chapati';

/* ============= COLORS ============= */

// PRIMARY COLORS

$primary-one: #016D38;
$primary-one-dark: #005833;
$primary-one-light: #02d46f;

// SECONDARY COLORS

$secondary-one: #ffdc5d;
$secondary-one-dark: #c9ab2a;
$secondary-one-light: #fff078;

// COMPLEMENTARY COLORS

$complementary-one: #f4f2ee;
$complementary-two: #a28e4d;
$complementary-three: #9BE9BA;

// COLORS TEXT

$text-01: #000;
$text-02: #333;
$text-03: #666;
$text-04: #FFF;
$text-05: #434343;
$text-06: #354043;
$text-07: #716122;

// COLOR UI

$ui-01: #1B1B1B;
$ui-02: #36474F;
$ui-03: #999;
$ui-04: #CCC;
$ui-05: #FBFBFB;
$ui-06: #f5f5f5;
$ui-07: #EEE;
$ui-08: #FFF;
$ui-09: #F6F9F7;

// GRAYSCALE

$white:     #fff !default;
$gray-base: #181b1e !default;
$gray-100:  #f0f3f5 !default; // lighten($gray-base, 85%);
$gray-200:  #e4e7ea !default; // lighten($gray-base, 80%);
$gray-300:  #c8ced3 !default; // lighten($gray-base, 70%);
$gray-400:  #acb4bc !default; // lighten($gray-base, 60%);
$gray-500:  #8f9ba6 !default; // lighten($gray-base, 50%);
$gray-600:  #73818f !default; // lighten($gray-base, 40%);
$gray-700:  #5c6873 !default; // lighten($gray-base, 30%);
$gray-800:  #2f353a !default; // lighten($gray-base, 10%);
$gray-900:  #23282c !default; // lighten($gray-base, 5%);
$black:     #000 !default;

// COLOR BASE

$blue:       #20a8d8 !default;
$indigo:     #6610f2 !default;
$purple:     #6f42c1 !default;
$pink:       #e83e8c !default;
$red:        #f86c6b !default;
$orange:     #f8cb00 !default;
$yellow:     #ffc107 !default;
$green:      #4dbd74 !default;
$teal:       #20c997 !default;
$cyan:       #17a2b8 !default;
$light-blue: #63c2de !default;

// ALERTS

$color-warning: #FFB412;
$color-error: #d14b41;
$color-sucess: #6fb453;

$default-controls-01: #6FB453;
$default-controls-02: #0074FC;
$default-controls-02-dark: #0056B3;
$default-controls-02-light: #b3d2f7;

// BORDER

$color-border-1: #ced4da;
$color-border-2: #999;


// Brand Logo

$brand-logo: url('../../../../assets/img/brand/seguros-color.svg');
$back-img: url('../../../../assets/img/background/ilustracion-ciudad.svg');

/* ============= SPACING ============= */

$spacing-tiny: 8px;
$spacing-small: 16px;
$spacing-base: 24px;
$spacing-medium: 36px;
$spacing-large: 48px;
$spacing-xlarge: 64px;
$spacing-huge: 128px;
